@import '~font-awesome/css/font-awesome.css';
@import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Hide scrollbars for all elements */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  width: 0px;  /* Safari and Chrome */
  height: 0px; /* Safari and Chrome */
}

.custom-backdrop {
  background: black;
  opacity: 0.8 !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-dropdown-menu {
  min-width: 140px;
}

.custom-card-background {
  background: linear-gradient(121.51deg, #FFFFFF 4.2%, #C6FFF7 93.09%);
}

.custom-info-background {
  background: linear-gradient(119.86deg, #FFFFFF 1.53%, #FFFFFF 42.98%, #C6FFF7 117.54%);
}